import { createRef, useMemo } from 'react';

export default function useSectionRefs<T extends HTMLElement>({
  sections,
}: {
  sections: string[];
  offsetAdjustment?: number;
}) {
  const sectionRefs = useMemo(
    () =>
      sections.map((section) => ({
        key: section,
        ref: createRef<T>(),
      })),
    [sections]
  );

  const scrollToSection = (section: string) => {
    const ref = sectionRefs.find((ref) => ref.key === section)?.ref;
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return { sectionRefs, scrollToSection };
}
