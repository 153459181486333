import classNames from 'classnames';
import DOMPurify from 'isomorphic-dompurify';
import { Configure, Hits, InstantSearch } from 'react-instantsearch';
import type {
  BrandedPage,
  CreditCardComponent,
  StrapiImage,
} from '@cardo/types';
import { getInfluencerCalculatorLink } from '@cardo/lib';
import { searchClient } from '~/lib/search';
import { generateImgProps } from '~/lib/strapiImage';
import { envPrefixed, useFooterContent } from '~/lib/utils';
import CreditCard from '../blocks/CreditCard';
import BrandedPageNav from '../BrandedPageNav';
import { LayoutPadding } from '../layout/Layout';
import EmptyQueryBoundary from '../search/EmptyQueryBoundary';
import FullCreditCardHit from '../search/FullCreditCardHit';
import { SocialLink } from '@cardo/ui';
import LayoutPaddingX from '../layout/LayoutPaddingX';
import { useRef } from 'react';
import logo from '~/images/logo-wide.svg';
import purpleEllipse from '~/images/purple-ellipse.webp';
import blueEllipse from '~/images/blue-ellipse.svg';
import background from '~/images/background-no-ellipses.webp';
import FooterNav from '../layout/FooterNav';
import AdvertiserDisclosure from '../AdvertiserDisclosure';

export const topRightEllipseClassNamesNoHero =
  '-right-32 md:-right-16 -top-16 md:-top-40';
export const bottomLeftEllipseClassNamesNoHero =
  '-left-32 md:-left-16 -bottom-8 md:-bottom-12';

type TopPicksPageProps = {
  brandedPage: BrandedPage;
  brandedPages: BrandedPage[];
  bankingPageEnabled?: boolean;
  businessBankingPageEnabled?: boolean;
};

export default function TopPicksPage({
  brandedPage,
  brandedPages,
  bankingPageEnabled = false,
  businessBankingPageEnabled = false,
}: TopPicksPageProps) {
  const topPicksCategoryPages = brandedPages.filter(
    (page) => page.attributes.pageType === 'top-picks-category'
  );

  const creditCardComponents = brandedPage.attributes.blocks?.filter(
    (block): block is CreditCardComponent =>
      block.__component === 'client.credit-card'
  );

  const userAvatar = brandedPage.attributes.influencer.data.attributes
    .avatar as unknown as StrapiImage;

  const footer = useFooterContent();
  const mobileHeaderHeightRef = useRef<HTMLElement>(null);
  const mobileHeaderHeight = mobileHeaderHeightRef.current?.offsetHeight ?? 65;

  return (
    <div className="relative min-h-screen w-screen">
      <LayoutPaddingX className="shadow-md bg-white">
        <header className="hidden flex-col items-center py-5 md:flex">
          <nav className="flex items-center gap-5 w-full justify-between">
            <img src={logo} alt="cardonomics logo" />
            <AdvertiserDisclosure className="relative hidden md:flex" />
          </nav>
        </header>
      </LayoutPaddingX>
      <header
        className="fixed z-[1000] flex h-auto w-full md:hidden"
        ref={mobileHeaderHeightRef}
      >
        <nav className="w-full">
          <div className="flex w-full flex-col space-y-2 bg-white px-4 py-4 shadow">
            <div className="relative flex w-full items-center justify-center gap-3">
              <img src={logo} alt="cardonomics logo" />
            </div>
          </div>
        </nav>
      </header>
      <div
        className="min-h-[65px] md:hidden"
        style={{
          height: mobileHeaderHeight,
        }}
      ></div>
      <div className={'flex flex-grow flex-col relative pt-6 h-fit'}>
        <LayoutPaddingX className="absolute left-0 right-0 top-0 flex justify-center md:hidden px-8 py-4">
          <AdvertiserDisclosure className="relative" />
        </LayoutPaddingX>
        <div className="absolute left-0 right-0 top-0 z-[-1] h-full w-full overflow-hidden">
          <img
            src={background}
            alt="background"
            className="h-full w-full object-cover"
          />
          <img
            src={blueEllipse}
            alt="purple ellipse"
            className={classNames('absolute', topRightEllipseClassNamesNoHero)}
          />
          <img
            src={purpleEllipse}
            alt="purple ellipse"
            className={classNames(
              'absolute',
              bottomLeftEllipseClassNamesNoHero
            )}
          />
        </div>
        <LayoutPaddingX className="h-full overflow-hidden">
          <section className="flex h-full flex-grow items-center justify-center">
            <div
              className={classNames(
                'flex flex-col pt-16 pb-20',
                brandedPage.attributes.influencer.data.attributes.socialLinks
                  ?.length > 0
                  ? 'gap-2'
                  : 'gap-8',
                topPicksCategoryPages.length > 0 && 'mb-10'
              )}
            >
              <div className="flex flex-col items-center gap-4">
                <div
                  className={classNames(
                    'flex h-44 w-44 items-center justify-center overflow-hidden rounded-full border shadow-md',
                    userAvatar.data && 'bg-white',
                    !userAvatar.data && 'bg-theme-blue-darkest'
                  )}
                >
                  {userAvatar.data && (
                    <img
                      {...generateImgProps(userAvatar)}
                      className="object-contain"
                      alt={`${brandedPage.attributes.influencer.data.attributes.username}'s avatar`}
                    />
                  )}
                </div>
              </div>
              {brandedPage.attributes.influencer.data.attributes.socialLinks
                ?.length > 0 && (
                <div className="mx-auto flex gap-3 pb-6 pt-4">
                  {brandedPage.attributes.influencer.data.attributes.socialLinks.map(
                    (socialLink) => (
                      <SocialLink key={socialLink.id} {...socialLink} />
                    )
                  )}
                </div>
              )}
              <div className="flex flex-col gap-4">
                <h1 className="text-center">
                  {brandedPage.attributes.pageTitle}
                </h1>
                {brandedPage.attributes.pageDescription && (
                  <div
                    className={classNames(
                      'max-w-4/5 prose-p:mb-2 text-center',
                      brandedPage.attributes.pageDescription.length > 200
                        ? 'sm:max-w-[900px]'
                        : 'sm:max-w-[600px]'
                    )}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        brandedPage.attributes.pageDescription
                      ),
                    }}
                  ></div>
                )}
              </div>
            </div>
          </section>
        </LayoutPaddingX>
      </div>
      <LayoutPadding>
        <main>
          {searchClient && (
            <InstantSearch
              searchClient={searchClient}
              indexName={envPrefixed('api::credit-card.credit-card')}
              future={{
                preserveSharedStateOnUnmount: true,
              }}
            >
              <Configure filters="NOT hideFromInfluencers:true" />
              <div className="relative mx-auto w-full max-w-[1200px] pt-20 xl:w-11/12">
                <div className="-mt-40 md:-mt-52 mb-8 w-full">
                  <BrandedPageNav
                    brandedPages={brandedPages}
                    bankingPageEnabled={bankingPageEnabled}
                    businessBankingPageEnabled={businessBankingPageEnabled}
                  />
                </div>
                <EmptyQueryBoundary
                  fallback={
                    <>
                      <div className="flex flex-col gap-8">
                        {creditCardComponents.length === 0 && (
                          <p>Not recommending any cards right now.</p>
                        )}
                        {creditCardComponents.map((ccComponent) => {
                          if (!ccComponent.creditCard.data) return null;
                          return (
                            <CreditCard
                              key={ccComponent.id}
                              id={ccComponent.creditCard.data.id}
                              creditCard={ccComponent.creditCard}
                              editorial={ccComponent.editorial}
                              calculatorLink={getInfluencerCalculatorLink({
                                creditCard: ccComponent.creditCard.data,
                                influencer:
                                  brandedPage.attributes.influencer.data
                                    .attributes,
                              })}
                              hideIfHideFromInfluencer={true}
                            />
                          );
                        })}
                      </div>
                    </>
                  }
                >
                  <Hits
                    hitComponent={FullCreditCardHit}
                    classNames={{
                      list: 'flex flex-col gap-8',
                    }}
                  />
                </EmptyQueryBoundary>
              </div>
            </InstantSearch>
          )}
        </main>
      </LayoutPadding>
      <LayoutPadding className="bg-white">
        <div className="h-10 border-t border-indigo-700"></div>
        <footer className="flex flex-col space-y-8 text-xs text-gray-500">
          <div className="flex justify-between">
            <FooterNav />
            <div className="flex space-x-1">
              {footer?.data?.attributes?.socialLinks?.socialLinks?.map(
                (socialLink) => (
                  <SocialLink key={socialLink.id} {...socialLink} />
                )
              )}
            </div>
          </div>
          {footer?.data?.attributes?.content && (
            <div
              className="prose-sm max-w-none text-xs"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(footer.data.attributes.content),
              }}
            ></div>
          )}
        </footer>
      </LayoutPadding>
    </div>
  );
}
